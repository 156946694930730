<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <div style="padding-top:15px;display:inline-block;">
          <span>
            <h5 class="title" style="font-weight:350;padding-bottom: 10px;">
              <small>{{ tipoProprietario }} id: {{ idServidor }}</small>
            </h5></span
          >
        </div>
        <div class="switch-div" v-show="checkPapel()">
          <md-switch v-model="editionMode">Modo Edição </md-switch>
        </div>
        <div class="md-layout md-gutter md-alignment-center">
          <div class="md-layout-item">
            <h4 class="title" style="font-weight:350;">
              Telefone :
            </h4>
          </div>
          <div>
            <md-button
              v-show="showButton"
              type="button"
              class="md-raised mt-4 md-success"
              @click="addNovoTelefone()"
              :disabled="checkEditionDisabled()"
            >
              Adicionar
            </md-button>
          </div>
        </div>
        <small
          >Para Telefones do Brasil digite apenas numeros com DD.
          <br />Telefones internacionais escolha o país e coloque no formato
          apenas numeros e codigo de area entre (00) ou (000)</small
        >
        <div v-for="(telefone, index) in telefones" :key="index">
          <div
            v-if="!isFetching"
            style="padding-top:15px;display:inline-block;"
          >
            <span>
              <h5 class="title" style="font-weight:350;padding-bottom: 10px;">
                Telefone - {{ index }}
              </h5></span
            >
          </div>
          <div id="agrupamentoCampos" class="md-layout">
            <div
              v-if="!isFetching"
              class="md-layout-item md-small-size-100 md-size-20"
            >
              <ValidationProvider
                :name="'codigo país-' + index"
                :rules="{ oneOf: codigoPaisOptionAuto }"
                v-slot="{ passed, failed, errors }"
              >
                <md-autocomplete
                  v-model="telefone.codigoPaisLegend"
                  :md-options="codigoPaisOptionAuto"
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                  :md-fuzzy-search="false"
                  @md-selected="selectedCodigoPais(index)"
                  :disabled="checkEditionDisabled()"
                >
                  <label>Codigo Pais</label>
                </md-autocomplete>

                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-25">
              <ValidationProvider
                :name="'numero-' + index"
                :rules="checked_rule"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Numero</label>
                  <md-input
                    v-model="telefone.numero"
                    type="text"
                    :maxlength="14"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-25">
              <ValidationProvider
                :name="'tipo telefone-' + index"
                :rules="{
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="tipoTelefoneSelect"
                    >Selecionar Tipo Telefone</label
                  >
                  <md-select
                    v-model="telefone.tipo"
                    name="tipoTelefoneSelect"
                    :disabled="checkEditionDisabled()"
                  >
                    <md-option
                      v-for="item in tipoTelefoneSelectOption"
                      :key="item.id"
                      :label="item.tipo"
                      :value="item.tipo"
                    >
                      {{ item.tipo }}
                    </md-option>
                  </md-select>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-xs-2">
            <label>&nbsp;</label>
            <md-button
              v-show="showButton"
              type="button"
              class="md-just-icon md-danger"
              @click="removeTelefone(index)"
              :disabled="checkEditionDisabled()"
            >
              <md-icon>close</md-icon>
            </md-button>
            <label style="padding-left: 20px">&nbsp;</label>
            <md-button
              v-show="showButton"
              type="button"
              class="md-raised md-success"
              :disabled="checkEditionDisabled()"
              @click="salvar(index)"
            >
              SALVAR
            </md-button>
            &nbsp;
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { extend, localize } from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import { store } from "../../../store";
import { mapState, mapActions, mapGetters } from "vuex";
import * as rules from "vee-validate/dist/rules";
import { NavTabsCard } from "@/components";
import Vue from "vue";
import Swal from "sweetalert2";
import { NOME_CAMPOS } from "@/pages/Dashboard/js/nomeCampos";
import { CODIGO_PAIS } from "@/pages/Dashboard/js/telefoneCodigoPais";
import { telefoneMask } from "@/pages/Dashboard/js/telefoneMask";

var pt_br = pt_BR;
var names = NOME_CAMPOS;
Vue.set(pt_br, "names", names);
for (let key in pt_br.messages) {
  if (key === "regex") {
    pt_br.messages.regex =
      "Telefone deve ter formato (99)99999-9999 ou (99)9999-9999 .";
  }
}
for (let key in pt_br.messages) {
  extend(key, rules[key]);
}
extend("oneOf", {
  message: "O valor do Codigo Pais não corresponde a de um da lista."
});
localize("pt_BR", pt_br);

/*extend("codigoPais", {
  validate: value => {
    let codigoPais = value.split("-");
    console.log(codigoPais[0]);
    let index = -1;
    let resultItem = this.codigoPaisOption.find(function(item, i) {
      if (item.codigo.toString() === codigoPais[0]) {
        index = i;
        return true;
      }
    });
    return resultItem === true ? true : false;
  },
  message: "O valor do Codigo Pais não corresponde a de um da lista."
});*/

export const TIPO_TELEFONE = [
  { id: 1, tipo: "RESIDENCIAL" },
  { id: 2, tipo: "COMERCIAL" },
  { id: 3, tipo: "CELULAR" }
];

export default {
  props: {
    id: {
      type: String
    },
    isEdition: {
      type: Boolean
    },
    tipoProprietario: { type: String }
  },
  computed: {
    ...mapState("auth", ["user_profile"]),
    ...mapGetters("servidor_publico", {
      servidorPublicoSelected: "servidorPublicoState"
    }),
    ...mapGetters("pensionista", {
      pensionistaSelected: "pensionistaState"
    }),
    ...mapGetters("telefone", {
      telefoneState: "telefoneState"
    }),
    user_profile_state: state => state.user_profile,
    numero() {
      return this.telefones.map(telefone => telefone.numero);
    },
    checked_rule() {
      if (this.regex !== "55-BRASIL") {
        return {
          regex: /^(!?(?:[0-9]\s?)?((\(([1-9][0-9])\))|(?:\(([0-9][0-9][0-9])\)))?(?:[0-9]*)|((\(([1-9][0-9])\))|(([1-9][0-9])))(?:((?:9\d|[2-9])\d{3})\-?(\d{4})))$/,
          required: true
        };
      }
      return {
        required: true,
        regex: /^((\(([1-9][0-9])\))|(([1-9][0-9])))(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/
      };
    }
  },
  beforeMount() {
    this.getPapel();
  },
  data() {
    return {
      idServidor: this.id,
      idPensionista: this.id,
      telefonesFromServ: [],
      telefonesFromPen: [],
      regex: "55-BRASIL",
      codigoPaisOption: CODIGO_PAIS,
      codigoPaisOptionAuto: "",
      tipoTelefoneSelect: "",
      tipoTelefoneSelectOption: TIPO_TELEFONE,
      editionMode: false,
      isDisabledEdition: true,
      isFetching: true,
      papel: "",
      telefone: {
        idTelefone: "",
        codigoPaisLegend: "55-BRASIL",
        codigoPais: 55,
        dd: "",
        numero: "",
        tipo: "",
        tipoProprietario: this.tipoProprietario
      },
      telefones: [],
      showButton: false
    };
  },
  mounted() {
    if (this.tipoProprietario === "SERVIDOR") {
      this.getTelefoneFromServ().then(() => {
        this.loadServidor();
      });
    }
    if (this.tipoProprietario === "PENSIONISTA") {
      this.getTelefoneFromPen().then(() => {
        this.loadPensionista();
      });
    }
  },
  methods: {
    ...mapActions("telefone", [
      "ActionSetTELEFONE",
      "ActionGetTelefoneFromServ",
      "ActionPOSTTelefone",
      "ActionPUTTelefone",
      "ActionDELETETelefone",
      "ActionGetTelefoneFromPen"
    ]),
    ...mapActions("servidor_publico", [
      "ActionSubmitServidorAllData",
      "ActionSetSERVIDOR_PUBLICO"
    ]),
    checkPapel() {
      return this.papel === "ADMIN" ? true : false;
    },
    addNovoTelefone: function() {
      this.telefones.push(Vue.util.extend({}, this.telefone));
    },
    emitToParent(value) {
      this.$emit("edition", value);
    },
    removeTelefone: function(index) {
      if (this.telefones[index].idTelefone === "") {
        Vue.delete(this.telefones, index);
      } else {
        Swal.fire({
          title: "Você tem certeza ?",
          text: `Se você excluir o Telefone apenas esta informação será excluida para este Cadastro ! Isto será irreversível !`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, excluir !",
          cancelButtonText: "CANCELAR",
          customClass: {
            confirmButton: "md-button md-success md-raised mt-4",
            cancelButton: "md-button md-danger md-raised mt-4"
          },
          buttonsStyling: false
        }).then(result => {
          if (result.value) {
            this.deleteTelefone(this.telefones[index], index);
          }
        });
      }
    },
    async deleteTelefone(tel, index) {
      try {
        let id = tel.idTelefone;
        let papel = this.papel;
        let response = await this.ActionDELETETelefone({ id, papel });
        if (response.status === 200) {
          this.mensagem = response.body.mensagem;
          Vue.delete(this.telefones, index);
          this.ActionSetTELEFONE(this.telefones);
          if (this.mensagem.includes("não")) {
            Swal.fire({
              title: `Telefone: Não Excluido.`,
              icon: "error",
              text: this.mensagem,
              text: `${this.mensagem}`,
              customClass: {
                confirmButton: "md-button md-raised md-danger btn-fill"
              },
              buttonsStyling: false
            });
          } else {
            Swal.fire({
              icon: "success",
              title: `Telefone: Excluido!`,
              text: `${this.mensagem}`,
              customClass: {
                confirmButton: "md-button md-raised mt-4 md-success btn-fill"
              },
              buttonsStyling: false
            });
          }
        }
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    checkEditionDisabled() {
      if (this.isDisabledEdition === true) {
        return "disabled";
      } else {
        return null;
      }
    },
    async loadForm() {
      let copiedJSON = Object.keys(this.codigoPaisOption).map(key => {
        return (
          this.codigoPaisOption[key].codigo +
          "-" +
          this.codigoPaisOption[key].pais.toUpperCase()
        );
      });
      this.codigoPaisOptionAuto = copiedJSON;

      if (this.telefoneState.length > 0) {
        Object.keys(this.telefoneState).map(key => {
          return this.telefones.push(
            Vue.util.extend({}, this.telefoneState[key])
          );
        });
      } else {
        if (this.telefonesFromServ.length > 0) {
          this.telefonesFromServ.map(tel => {
            let telefoneJson = tel;
            if (telefoneJson.dd !== null) {
              telefoneJson.numero = telefoneMask(
                telefoneJson.dd.toString() + telefoneJson.numero.toString()
              );
            } else {
              if (telefoneJson.codigoPais === 55) {
                telefoneJson.numero = telefoneMask(
                  telefoneJson.numero.toString()
                );
              } else {
                telefoneJson.numero = telefoneJson.numero.toString();
              }
            }
            let index = -1;
            let resultItem = this.codigoPaisOptionAuto.find(function(item, i) {
              let codigoPaisLegend = item.split("-")[0];
              if (
                codigoPaisLegend.toString() ===
                telefoneJson.codigoPais.toString()
              ) {
                index = i;
                return item;
              }
            });
            telefoneJson.codigoPaisLegend = resultItem;
            telefoneJson = {
              ...telefoneJson,
              idEntidade: this.servidorPublicoSelected.idEntidade
            };
            this.telefones.push(Vue.util.extend({}, telefoneJson));
          });
          this.ActionSetTELEFONE(this.telefones);
        } else if (this.telefonesFromPen.length > 0) {
          this.telefonesFromPen.map(tel => {
            let telefoneJson = tel;

            if (telefoneJson.dd !== null) {
              telefoneJson.numero = telefoneMask(
                telefoneJson.dd.toString() + telefoneJson.numero.toString()
              );
            } else {
              if (telefoneJson.codigoPais === 55) {
                telefoneJson.numero = telefoneMask(
                  telefoneJson.numero.toString()
                );
              } else {
                telefoneJson.numero = telefoneJson.numero.toString();
              }
            }
            let index = -1;
            let resultItem = this.codigoPaisOptionAuto.find(function(item, i) {
              let codigoPaisLegend = item.split("-")[0];
              if (
                codigoPaisLegend.toString() ===
                telefoneJson.codigoPais.toString()
              ) {
                index = i;
                return item;
              }
            });
            telefoneJson.codigoPaisLegend = resultItem;
            telefoneJson = {
              ...telefoneJson,
              idEntidade: this.pensionistaSelected.idEntidade
            };
            this.telefones.push(Vue.util.extend({}, telefoneJson));
          });
          this.ActionSetTELEFONE(this.telefones);
        } else {
          this.telefones = JSON.parse(
            JSON.stringify([
              {
                idTelefone: "",
                codigoPaisLegend: "55-BRASIL",
                codigoPais: 55,
                dd: "",
                numero: "",
                tipo: "",
                tipoProprietario: this.tipoProprietario
              }
            ])
          );
        }
      }
      this.isFetching = false;
      if (this.isEdition) {
        this.editionMode = true;
      }
    },
    async loadServidor() {
      if (
        this.servidorPublicoSelected.cpfServidor &&
        this.servidorPublicoSelected !== "" &&
        this.servidorPublicoSelected !== null
      ) {
        this.loadForm();
      } else {
        Swal.fire({
          title: "ATENÇÃO!",
          icon: "warning",
          text: "Por favor reinicie a partir do Cadastro !",
          customClass: {
            confirmButton: "md-button md-warning btn-fill"
          },
          buttonsStyling: false
        });
        window._Vue.$router.push({ name: "Cadastro" }).catch(err => {});
      }
    },
    async loadPensionista() {
      if (
        typeof this.pensionistaSelected !== "undefined" &&
        this.pensionistaSelected !== "" &&
        this.pensionistaSelected !== null &&
        this.pensionistaSelected.idPensionista
      ) {
        this.loadForm();
      } else {
        Swal.fire({
          title: "ATENÇÃO!",
          icon: "warning",
          text: "Por favor reinicie a partir do Cadastro !",
          customClass: {
            confirmButton: "md-button md-warning btn-fill"
          },
          buttonsStyling: false
        });
        window._Vue.$router
          .push({ name: "Cadastro Pensionista" })
          .catch(err => {});
      }
    },
    async getTelefoneFromServ() {
      try {
        let id = this.idServidor;
        let papel = this.papel;
        let idEntidade = this.user_profile_state.idEntidade;
        const response = await this.ActionGetTelefoneFromServ({
          id,
          papel,
          idEntidade
        }).then(res => {
          let response = res;
          if (response.status === 200) {
            if (response.body.mensagem) {
              this.mensagem = response.body.mensagem;
              if (!this.mensagem.includes("não")) {
                Swal.fire({
                  icon: "error",
                  title: `ERROR - idServidor: ${this.idServidor}`,
                  text: `${this.mensagem}`,
                  customClass: {
                    confirmButton:
                      "md-button md-raised mt-4 md-success btn-fill"
                  },
                  buttonsStyling: false
                });
              }
            } else {
              return (this.telefonesFromServ = response.body);
            }
          }
        });
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    async getTelefoneFromPen() {
      try {
        let id = this.idPensionista;
        let papel = this.papel;
        let idEntidade = this.user_profile_state.idEntidade;
        const response = await this.ActionGetTelefoneFromPen({
          id,
          papel,
          idEntidade
        }).then(res => {
          let response = res;
          if (response.status === 200) {
            if (response.body.mensagem) {
              this.mensagem = response.body.mensagem;
              if (!this.mensagem.includes("não")) {
                Swal.fire({
                  icon: "error",
                  title: `ERROR - idPensionista: ${this.idPensionista}`,
                  text: `${this.mensagem}`,
                  customClass: {
                    confirmButton:
                      "md-button md-raised mt-4 md-success btn-fill"
                  },
                  buttonsStyling: false
                });
              }
            } else {
              return (this.telefonesFromPen = response.body);
            }
          }
        });
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    async salvar(index) {
      let checkerror = true;
      this.$refs.observer.validate().then(() => {
        this.delay(100).then(() => {
          if (this.$refs.observer.errors) {
            checkerror = Object.keys(this.$refs.observer.errors).some(key => {
              let checkerrorArray =
                this.$refs.observer.errors[key].length > 0 ? true : false;
              return checkerrorArray;
            });
            if (!checkerror) {
              let idTelefone = this.telefones[index].idTelefone;
              try {
                let papel = this.papel;
                if (
                  this.telefones[index].idTelefone !== "" &&
                  this.telefones[index].idEntidade !== ""
                ) {
                  let id = idTelefone;
                  let telefone = Object.assign({}, this.telefones[index]);
                  delete telefone.idEntidade;

                  if (this.tipoProprietario === "SERVIDOR") {
                    telefone = {
                      ...telefone,
                      servidorPublico: this.idServidor
                    };
                  } else if (this.tipoProprietario === "PENSIONISTA") {
                    telefone = { ...telefone, pensionista: this.idPensionista };
                  }

                  let response = this.ActionPUTTelefone({
                    id,
                    papel,
                    telefone
                  }).then(res => {
                    let response = res;
                    if (response.status === 200) {
                      if (response.body.mensagem) {
                        this.mensagem = response.body.mensagem;
                        if (this.mensagem.includes("não")) {
                          Swal.fire({
                            //title: "Sucesso",
                            icon: "warning",
                            text: this.mensagem,
                            showCancelButton: false,
                            customClass: {
                              confirmButton: "md-button md-success btn-fill"
                            },
                            buttonsStyling: false,
                            confirmButtonText: "Ok"
                          });
                        } else {
                          this.ActionSetTELEFONE(this.telefones);
                          Swal.fire({
                            //title: "Sucesso",
                            icon: "success",
                            text: this.mensagem,
                            showCancelButton: false,
                            customClass: {
                              confirmButton: "md-button md-success btn-fill"
                            },
                            buttonsStyling: false,
                            confirmButtonText: "Ok"
                          });
                          this.editionMode = false;
                        }
                      }
                    }
                  });
                } else {
                  let telefone = Object.assign({}, this.telefones[index]);
                  if (this.tipoProprietario === "SERVIDOR") {
                    telefone = {
                      ...telefone,
                      servidorPublico: this.idServidor
                    };
                  } else if (this.tipoProprietario === "PENSIONISTA") {
                    telefone = { ...telefone, pensionista: this.idPensionista };
                  }
                  let response = this.ActionPOSTTelefone({
                    papel,
                    telefone
                  }).then(res => {
                    let response = res;
                    if (response.status === 200) {
                      if (response.body.mensagem) {
                        this.mensagem = response.body.mensagem;
                        if (this.mensagem.includes("não")) {
                          Swal.fire({
                            //title: "Sucesso",
                            icon: "warning",
                            text: this.mensagem,
                            showCancelButton: false,
                            customClass: {
                              confirmButton: "md-button md-success btn-fill"
                            },
                            buttonsStyling: false,
                            confirmButtonText: "Ok"
                          });
                        } else {
                          this.telefones[index].idTelefone =
                            response.body.idTelefone;
                          Swal.fire({
                            //title: "Sucesso",
                            icon: "success",
                            text: this.mensagem,
                            showCancelButton: false,
                            customClass: {
                              confirmButton: "md-button md-success btn-fill"
                            },
                            buttonsStyling: false,
                            confirmButtonText: "Ok"
                          });
                          this.editionMode = false;
                        }
                      }
                      this.ActionSetTELEFONE(this.telefones);
                    }
                  });
                }
              } catch (err) {
                console.log(err);
                this.handleError(err);
              }
            }
          }
        });
      });
    },
    getPapel() {
      this.papel = this.user_profile_state.papel;
    },
    selectedCodigoPais(key) {
      let codigoPais = this.telefones[key].codigoPaisLegend.split("-");
      let index = -1;
      let resultItem = this.codigoPaisOption.find(function(item, i) {
        if (item.codigo.toString() === codigoPais[0]) {
          index = i;
          return item;
        }
      });
      this.telefones[key].codigoPais = resultItem.codigo;
      this.regex = this.telefones[key].codigoPaisLegend;
    },
    handleError(err) {
      console.log(err);
      if (
        err === 400 ||
        err === 500 ||
        err === 404 ||
        err.status === 400 ||
        err.status === 500 ||
        err.status === 404
      ) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (err.status === 401 || err.data === "Unauthorized") {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          this.mensagem = err.body.mensagem;
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: "Erro!",
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  },
  watch: {
    numero(val) {
      Object.keys(this.telefones).map(key => {
        if (this.telefones[key].codigoPais === 55) {
          let numero = telefoneMask(val[key]);
          this.telefones[key].numero = numero;
          if (this.telefones[key].numero === val[key]) {
            if (
              /^((\(([1-9][0-9])\))|(([1-9][0-9])))(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/.test(
                val
              )
              // /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/.test(
            ) {
              val = val[key]
                .toString()
                .split("(")[1]
                .split(")");
              //console.log(val[0]);
              this.telefones[key].dd = val[0];
            }
          }
        }
      });
    },
    editionMode: function(val, oldVal) {
      let edition = val;
      if (edition) {
        this.isDisabledEdition = false;
        this.showButton = true;
        ///checkEditionDisabled();
      } else {
        this.isDisabledEdition = true;
        this.showButton = false;
      }
      this.emitToParent(edition);
    }
  }
};
</script>
<style>
::v-deep .paginated-table .md-table-head-label {
  font-size: 0.875rem;
  font-weight: 400;
}
.md-table-cell {
  font-size: 12px;
}
#error {
  color: red;
  font-weight: 400;
  font-size: 0.65rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}

.md-card .md-card-actions {
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}
.space-tool-bar {
  width: 50px;
  height: 50px;
}

.md-button.md-info.md-simple,
.md-button.md-info.md-simple:hover,
.md-button.md-info.md-simple:focus,
.md-button.md-info.md-simple:active,
.md-button.md-info.md-simple.active,
.md-button.md-info.md-simple:active:focus,
.md-button.md-info.md-simple:active:hover,
.md-button.md-info.md-simple.active:focus,
.md-button.md-info.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}

.md-button.md-success.md-simple,
.md-button.md-success.md-simple:hover,
.md-button.md-success.md-simple:focus,
.md-button.md-success.md-simple:active,
.md-button.md-success.md-simple.active,
.md-button.md-success.md-simple:active:focus,
.md-button.md-success.md-simple:active:hover,
.md-button.md-success.md-simple.active:focus,
.md-button.md-success.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}
.paginated-table .md-table-cell-container .md-button .md-button-content i {
  font-size: 24px !important;
}
::v-deep .modal-container {
  max-width: 720px;
}

.modal-text {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

::v-deep .md-count {
  display: none;
}

#agrupamentoCampos {
  border: 1px solid rgba(128, 128, 128, 0.3);
  border-radius: 5px;
  padding-top: 12px;
  padding-bottom: 12px;
}

::v-deep .md-radio.md-disabled .md-radio-label,
.md-radio.md-disabled .md-radio-container {
  opacity: 1;
  border-color: rgba(0, 0, 0, 1) !important;
}

::v-deep .md-input {
  text-transform: uppercase;
}

.uppercase {
  text-transform: uppercase;
}

.switch-div {
  /*display: inline-block;*/
  float: right;
}

#container {
  display: flex; /* establish flex container */
  flex-direction: row; /* default value; can be omitted */
  flex-wrap: nowrap; /* default value; can be omitted */
  justify-content: space-between; /* switched from default (flex-start, see below) */
  background-color: lightyellow;
}
#container > div {
  width: 100px;
  height: 100px;
  border: 2px dashed red;
}
</style>
