export const CODIGO_PAIS = [
  { codigo: 93, pais: "Afeganistão", continente: "Ásia" },
  { codigo: 27, pais: "África do Sul", continente: "África" },
  { codigo: 355, pais: "Albânia", continente: "Europa" },
  { codigo: 49, pais: "Alemanha", continente: "Europa" },
  { codigo: 376, pais: "Andorra", continente: "Europa" },
  { codigo: 244, pais: "Angola", continente: "África" },
  { codigo: 1, pais: "Anguilla", continente: "América Central" },
  { codigo: 1, pais: "Antígua e Barbuda", continente: "América Central" },
  { codigo: 599, pais: "Antilhas Holandesas", continente: "América Central" },
  { codigo: 966, pais: "Arábia Saudita", continente: "Ásia" },
  { codigo: 213, pais: "Argélia", continente: "África" },
  { codigo: 54, pais: "Argentina", continente: "América do Sul" },
  { codigo: 374, pais: "Armênia", continente: "Ásia" },
  { codigo: 297, pais: "Aruba", continente: "América Central" },
  { codigo: 247, pais: "Ascensão", continente: "África" },
  { codigo: 61, pais: "Austrália", continente: "Oceania" },
  { codigo: 43, pais: "Áustria", continente: "Europa" },
  { codigo: 994, pais: "Azerbaijão", continente: "Ásia" },
  { codigo: 1, pais: "Bahamas", continente: "América Central" },
  { codigo: 880, pais: "Bangladesh", continente: "Ásia" },
  { codigo: 1, pais: "Barbados", continente: "América Central" },
  { codigo: 973, pais: "Bahrein", continente: "Ásia" },
  { codigo: 32, pais: "Bélgica", continente: "Europa" },
  { codigo: 501, pais: "Belize", continente: "América Central" },
  { codigo: 229, pais: "Benim", continente: "África" },
  { codigo: 1, pais: "Bermudas", continente: "América Central" },
  { codigo: 375, pais: "Bielorrússia", continente: "Europa" },
  { codigo: 591, pais: "Bolívia", continente: "América do Sul" },
  { codigo: 387, pais: "Bósnia e Herzegovina", continente: "Europa" },
  { codigo: 267, pais: "Botswana", continente: "África" },
  { codigo: 55, pais: "Brasil", continente: "América do Sul" },
  { codigo: 673, pais: "Brunei", continente: "Ásia" },
  { codigo: 359, pais: "Bulgária", continente: "Europa" },
  { codigo: 226, pais: "Burkina Faso", continente: "África" },
  { codigo: 257, pais: "Burundi", continente: "África" },
  { codigo: 975, pais: "Butão", continente: "Ásia" },
  { codigo: 238, pais: "Cabo Verde", continente: "África" },
  { codigo: 237, pais: "Camarões", continente: "África" },
  { codigo: 855, pais: "Camboja", continente: "Ásia" },
  { codigo: 1, pais: "Canadá", continente: "América do Norte" },
  { codigo: 7, pais: "Cazaquistão", continente: "Ásia" },
  { codigo: 237, pais: "Chade", continente: "África" },
  { codigo: 56, pais: "Chile", continente: "América do Sul" },
  { codigo: 86, pais: "República Popular da China", continente: "Ásia" },
  { codigo: 357, pais: "Chipre", continente: "Europa" },
  { codigo: 57, pais: "Colômbia", continente: "América do Sul" },
  { codigo: 269, pais: "Comores", continente: "África" },
  { codigo: 242, pais: "Congo-Brazzaville", continente: "África" },
  { codigo: 243, pais: "Congo-Kinshasa", continente: "África" },
  { codigo: 850, pais: "Coreia do Norte", continente: "Ásia" },
  { codigo: 82, pais: "Coreia do Sul", continente: "Ásia" },
  { codigo: 225, pais: "Costa do Marfim", continente: "África" },
  { codigo: 506, pais: "Costa Rica", continente: "América Central" },
  { codigo: 385, pais: "Croácia", continente: "Europa" },
  { codigo: 53, pais: "Cuba", continente: "América Central" },
  { codigo: 45, pais: "Dinamarca", continente: "Europa" },
  { codigo: 253, pais: "Djibuti", continente: "África" },
  { codigo: 1, pais: "Dominica", continente: "América Central" },
  { codigo: 20, pais: "Egipto", continente: "África/Ásia" },
  { codigo: 503, pais: "El Salvador", continente: "América Central" },
  { codigo: 971, pais: "Emirados Árabes Unidos", continente: "Ásia" },
  { codigo: 593, pais: "Equador", continente: "América do Sul" },
  { codigo: 291, pais: "Eritreia", continente: "África" },
  { codigo: 421, pais: "Eslováquia", continente: "Europa" },
  { codigo: 386, pais: "Eslovénia", continente: "Europa" },
  { codigo: 34, pais: "Espanha", continente: "Europa" },
  { codigo: 1, pais: "Estados Unidos", continente: "América do Norte" },
  { codigo: 372, pais: "Estónia", continente: "Europa" },
  { codigo: 251, pais: "Etiópia", continente: "África" },
  { codigo: 679, pais: "Fiji", continente: "Oceania" },
  { codigo: 63, pais: "Filipinas", continente: "Ásia" },
  { codigo: 358, pais: "Finlândia", continente: "Europa" },
  { codigo: 33, pais: "França", continente: "Europa" },
  { codigo: 241, pais: "Gabão", continente: "África" },
  { codigo: 220, pais: "Gâmbia", continente: "África" },
  { codigo: 233, pais: "Gana", continente: "África" },
  { codigo: 995, pais: "Geórgia", continente: "Ásia" },
  { codigo: 350, pais: "Gibraltar", continente: "Europa" },
  { codigo: 1, pais: "Granada", continente: "América Central" },
  { codigo: 30, pais: "Grécia", continente: "Europa" },
  { codigo: 299, pais: "Groenlândia", continente: "América do Norte" },
  { codigo: 590, pais: "Guadalupe", continente: "América Central" },
  { codigo: 671, pais: "Guam", continente: "Oceania" },
  { codigo: 502, pais: "Guatemala", continente: "América Central" },
  { codigo: 592, pais: "Guiana", continente: "América do Sul" },
  { codigo: 594, pais: "Guiana Francesa", continente: "América do Sul" },
  { codigo: 224, pais: "Guiné", continente: "África" },
  { codigo: 245, pais: "Guiné-Bissau", continente: "África" },
  { codigo: 240, pais: "Guiné Equatorial", continente: "África" },
  { codigo: 509, pais: "Haiti", continente: "América Central" },
  { codigo: 504, pais: "Honduras", continente: "América Central" },
  { codigo: 852, pais: "Hong Kong", continente: "Ásia" },
  { codigo: 36, pais: "Hungria", continente: "Europa" },
  { codigo: 967, pais: "Iêmen", continente: "Ásia" },
  { codigo: 1, pais: "Ilhas Cayman", continente: "América Central" },
  { codigo: 672, pais: "Ilha Christmas", continente: "Oceania" },
  { codigo: 672, pais: "Ilhas Cocos", continente: "Oceania" },
  { codigo: 682, pais: "Ilhas Cook", continente: "Oceania" },
  { codigo: 298, pais: "Ilhas Féroe", continente: "Europa" },
  { codigo: 672, pais: "Ilha Heard e Ilhas McDonald", continente: "Oceania" },
  { codigo: 960, pais: "Maldivas", continente: "Ásia" },
  { codigo: 500, pais: "Ilhas Malvinas", continente: "América do Sul" },
  { codigo: 1, pais: "Ilhas Marianas do Norte", continente: "Oceania" },
  { codigo: 692, pais: "Ilhas Marshall", continente: "Oceania" },
  { codigo: 672, pais: "Ilha Norfolk", continente: "Oceania" },
  { codigo: 677, pais: "Ilhas Salomão", continente: "Oceania" },
  {
    codigo: 1,
    pais: "Ilhas Virgens Americanas",
    continente: "América Central"
  },
  {
    codigo: 1,
    pais: "Ilhas Virgens Britânicas",
    continente: "América Central"
  },
  { codigo: 91, pais: "Índia", continente: "Ásia" },
  { codigo: 62, pais: "Indonésia", continente: "Ásia/Oceania" },
  { codigo: 98, pais: "Irã", continente: "Ásia" },
  { codigo: 964, pais: "Iraque", continente: "Ásia" },
  { codigo: 353, pais: "Irlanda", continente: "Europa" },
  { codigo: 354, pais: "Islândia", continente: "Europa" },
  { codigo: 972, pais: " Israel", continente: "Ásia" },
  { codigo: 39, pais: "Itália", continente: "Europa" },
  { codigo: 1, pais: "Jamaica", continente: "América Central" },
  { codigo: 81, pais: "Japão", continente: "Ásia" },
  { codigo: 962, pais: "Jordânia", continente: "Ásia" },
  { codigo: 686, pais: "Kiribati", continente: "Oceania" },
  { codigo: 383, pais: "Kosovo", continente: "Europa" },
  { codigo: 965, pais: "Kuwait", continente: "Ásia" },
  { codigo: 856, pais: "Laos", continente: "Ásia" },
  { codigo: 266, pais: "Lesoto", continente: "África" },
  { codigo: 371, pais: "Letônia", continente: "Europa" },
  { codigo: 961, pais: "Líbano", continente: "Ásia" },
  { codigo: 231, pais: "Libéria", continente: "África" },
  { codigo: 218, pais: "Líbia", continente: "África" },
  { codigo: 423, pais: "Liechtenstein", continente: "Europa" },
  { codigo: 370, pais: "Lituânia", continente: "Europa" },
  { codigo: 352, pais: "Luxemburgo", continente: "Europa" },
  { codigo: 853, pais: "Macau", continente: "Ásia" },
  { codigo: 389, pais: "República da Macedônia", continente: "Europa" },
  { codigo: 261, pais: "Madagascar", continente: "África" },
  { codigo: 60, pais: "Malásia", continente: "Ásia" },
  { codigo: 265, pais: "Malawi", continente: "África" },
  { codigo: 223, pais: "Mali", continente: "África" },
  { codigo: 356, pais: "Malta", continente: "Europa" },
  { codigo: 212, pais: "Marrocos", continente: "África" },
  { codigo: 596, pais: "Martinica", continente: "América Central" },
  { codigo: 230, pais: "Maurícia", continente: "África" },
  { codigo: 222, pais: "Mauritânia", continente: "África" },
  { codigo: 269, pais: "Mayotte", continente: "África" },
  { codigo: 52, pais: "México", continente: "América do Norte" },
  {
    codigo: 691,
    pais: "Estados Federados da Micronésia",
    continente: "Oceania"
  },
  { codigo: 258, pais: "Moçambique", continente: "África" },
  { codigo: 373, pais: "Moldávia", continente: "Europa" },
  { codigo: 377, pais: "Mônaco", continente: "Europa" },
  { codigo: 976, pais: "Mongólia", continente: "Ásia" },
  { codigo: 382, pais: "Montenegro", continente: "Europa" },
  { codigo: 1, pais: "Montserrat", continente: "América Central" },
  { codigo: 95, pais: "Myanmar", continente: "Ásia" },
  { codigo: 264, pais: "Namíbia", continente: "África" },
  { codigo: 674, pais: "Nauru", continente: "Oceania" },
  { codigo: 977, pais: "Nepal", continente: "Ásia" },
  { codigo: 505, pais: "Nicarágua", continente: "América Central" },
  { codigo: 227, pais: "Níger", continente: "África" },
  { codigo: 234, pais: "Nigéria", continente: "África" },
  { codigo: 683, pais: "Niue", continente: "Oceania" },
  { codigo: 47, pais: "Noruega", continente: "Europa" },
  { codigo: 687, pais: "Nova Caledônia", continente: "Oceania" },
  { codigo: 64, pais: "Nova Zelândia", continente: "Oceania" },
  { codigo: 968, pais: "Omã", continente: "Ásia" },
  { codigo: 31, pais: "Países Baixos", continente: "Europa" },
  { codigo: 680, pais: "Palau", continente: "Oceania" },
  { codigo: 970, pais: "Palestina", continente: "Ásia" },
  { codigo: 507, pais: "Panamá", continente: "América Central" },
  { codigo: 675, pais: "Papua-Nova Guiné", continente: "Oceania" },
  { codigo: 92, pais: "Paquistão", continente: "Ásia" },
  { codigo: 595, pais: "Paraguai", continente: "América do Sul" },
  { codigo: 51, pais: "Peru", continente: "América do Sul" },
  { codigo: 689, pais: "Polinésia Francesa", continente: "Oceania" },
  { codigo: 48, pais: "Polônia", continente: "Europa" },
  { codigo: 1, pais: "Porto Rico", continente: "América Central" },
  { codigo: 351, pais: "Portugal", continente: "Europa" },
  { codigo: 974, pais: "Qatar", continente: "Ásia" },
  { codigo: 254, pais: "Quênia", continente: "África" },
  { codigo: 996, pais: "Quirguistão", continente: "Ásia" },
  { codigo: 44, pais: "Reino Unido", continente: "Europa" },
  { codigo: 236, pais: "República Centro-Africana", continente: "África" },
  { codigo: 1, pais: "República Dominicana", continente: "América Central" },
  { codigo: 420, pais: "República Tcheca", continente: "Europa" },
  { codigo: 262, pais: "Reunião", continente: "África" },
  { codigo: 40, pais: "Romênia", continente: "Europa" },
  { codigo: 250, pais: "Ruanda", continente: "África" },
  { codigo: 7, pais: "Rússia", continente: "Europa/Ásia" },
  { codigo: 212, pais: "Saara Ocidental", continente: "África" },
  { codigo: 685, pais: "Samoa", continente: "Oceania" },
  { codigo: 1, pais: "Samoa Americana", continente: "Oceania" },
  { codigo: 290, pais: "Santa Helena (território)", continente: "África" },
  { codigo: 1, pais: "Santa Lúcia", continente: "América Central" },
  { codigo: 1, pais: "São Cristóvão e Nevis", continente: "América Central" },
  { codigo: 378, pais: "São Marinho", continente: "Europa" },
  {
    codigo: 508,
    pais: "Saint-Pierre e Miquelon",
    continente: "América do Norte"
  },
  { codigo: 239, pais: "São Tomé e Príncipe", continente: "África" },
  {
    codigo: 1,
    pais: "São Vicente e Granadinas",
    continente: "América Central"
  },
  { codigo: 248, pais: "Seicheles", continente: "África" },
  { codigo: 221, pais: "Senegal", continente: "África" },
  { codigo: 232, pais: "Serra Leoa", continente: "África" },
  { codigo: 381, pais: "Sérvia", continente: "Europa" },
  { codigo: 65, pais: "Singapura", continente: "Ásia" },
  { codigo: 963, pais: "Síria", continente: "Ásia" },
  { codigo: 252, pais: "Somália", continente: "África" },
  { codigo: 94, pais: "Sri Lanka", continente: "Ásia" },
  { codigo: 268, pais: "Suazilândia", continente: "África" },
  { codigo: 249, pais: "Sudão", continente: "África" },
  { codigo: 211, pais: "Sudão do Sul", continente: "África" },
  { codigo: 46, pais: "Suécia", continente: "Europa" },
  { codigo: 41, pais: "Suíça", continente: "Europa" },
  { codigo: 597, pais: "Suriname", continente: "América do Sul" },
  { codigo: 992, pais: "Tadjiquistão", continente: "Ásia" },
  { codigo: 66, pais: "Tailândia", continente: "Ásia" },
  { codigo: 886, pais: "República da China", continente: "Ásia" },
  { codigo: 255, pais: "Tanzânia", continente: "África" },
  {
    codigo: 246,
    pais: "Território Britânico do Oceano Índico",
    continente: "África"
  },
  { codigo: 670, pais: "Timor-Leste", continente: "Ásia" },
  { codigo: 228, pais: "Togo", continente: "África" },
  { codigo: 690, pais: "Tokelau", continente: "Oceania" },
  { codigo: 676, pais: "Tonga", continente: "Oceania" },
  { codigo: 1, pais: "Trinidad e Tobago", continente: "América Central" },
  { codigo: 216, pais: "Tunísia", continente: "África" },
  { codigo: 1, pais: "Turcas e Caicos", continente: "América Central" },
  { codigo: 993, pais: "Turquemenistão", continente: "Ásia" },
  { codigo: 90, pais: "Turquia", continente: "Ásia//Europa" },
  { codigo: 688, pais: "Tuvalu", continente: "Oceania" },
  { codigo: 380, pais: "Ucrânia", continente: "Europa" },
  { codigo: 256, pais: "Uganda", continente: "África" },
  { codigo: 598, pais: "Uruguai", continente: "América do Sul" },
  { codigo: 998, pais: "Uzbequistão", continente: "Ásia" },
  { codigo: 678, pais: "Vanuatu", continente: "Oceania" },
  { codigo: 379, pais: "Vaticano", continente: "Europa" },
  { codigo: 58, pais: "Venezuela", continente: "América do Sul" },
  { codigo: 84, pais: "Vietnã", continente: "Ásia" },
  { codigo: 681, pais: "Wallis e Futuna", continente: "Oceania" },
  { codigo: 260, pais: "Zâmbia", continente: "África" },
  { codigo: 263, pais: "Zimbábue", continente: "África" }
];
