export const NOME_CAMPOS = {
  "form.descricaoEntidade": "Descrição Entidade",
  "form.nomeEntidade": "Nome Entidade",
  "form.descricaoPapel": "Descrição Papel",
  "form.nomePapel": "Nome Papel",
  "form.primeiroNome": "Primeiro Nome",
  "form.sobrenome": "Sobrenome",
  "form.nomeMeio": "Nome do Meio",
  "form.email": "Email",
  "form.nomeusuario": "Nome de Usuário",
  "form.situacao": "Situação",
  "form.nomeCompleto": "Nome Completo",
  "form.cpfServidor": "CPF do Servidor",
  "form.dataNascimento": "Data de Nascimento",
  "form.dataObito": "Data de Obito",
  "form.diaAniversario": "Dia do Aniversário",
  "form.mesAniversario": "Mês de Aniversário",
  "form.sexoServidor": "Sexo do Servidor",
  "form.estadoCivil": "Estado Civil",
  "form.nivelEscolaridade": "Nivel Escolaridade",
  "form.nomeMae": "Nome da Mãe",
  "form.rgServidor": "RG do Servidor",
  "form.rgUfServidor": "UF do RG",
  "form.rgOrgExpedidorServ": "Orgão Expedidor do RG",
  "form.rgDataExpServ": "Data Expedição do RG",
  "form.numTitEleitor": "Numero Titulo Eleitor",
  "form.ctpsNum": "Carteira de Trabalho",
  "form.ctpsSerie": "Serie CTPS",
  "form.ctpsUf": "UF da CTPS",
  "form.pisPasep": "PIS/PASEP",
  "form.siapeServidor": "SIAPE",
  "form.siapeServidorDv": "DV Siape",
  "form.sigRegJur": "Sigla Regime Jurídico",
  "form.regimeJuridico": "Regime Jurídico",
  "form.codOrgao": "Código Orgão",
  "form.orgDescricao": "Orgão Descrição",
  "form.descricaoIngOrgao": "Descrição Ingresso Orgão",
  "form.dataIngressoOrgao": "Data Ingresso Orgão",
  "form.descricaoIngServPublico": "Descrição Ingresso Serviço Público",
  "form.dtIngServPublico": "Data Ingresso Serviço Público",
  "form.upag": "Unidade Pagadora",
  "form.upagDescricao": "Unidade Pagadora Descrição",
  "form.siglaUpag": "Sigla Unidade Pagadora",
  "form.ufUpag": "UF da Upag",
  "form.uorgExercicio": "Unidade Exercício",
  "form.nomeUnidadeExercicio": "Unidade Exercício Descrição",
  "form.siglaUnidadeExercicio": "Sigla Unidade Exercício",
  "form.ufUpag": "UF do Exercício",
  "form.grupoCargo": "Grupo do Cargo",
  "form.cargoDescricao": "Cargo Descrição",
  "form.classeCargo": "Classe do Cargo",
  "form.padraoCargo": "Padrão do Cargo",
  "form.nivelCargo": "Nível do Cargo",
  "form.uorgLotacao": "Unidade Lotação",
  "form.nomeUnidadeLotacao": "Unidade Lotação Descrição",
  "form.sigleUnidadeLotacao": "Sigla Unidade Lotação",
  "form.uorgLocalizacao": "Unidade Localização",
  "form.nomeUnidadeLotacao": "Unidade Localização Descrição",
  "form.sigleUnidadeLotacao": "Sigla Unidade Localização",
  "form.grupoAposentadoria": "Grupo Aposentadoria",
  "form.codAposentadoria": "Código Aposentadoria",
  "form.ocorrenciaAposentadoria": "Ocorrência Aposentadoria",
  "form.dtAposentadoria": "Data Aposentadoria",
  "form.codExclusao": "Código Exclusão",
  "form.ocorrenciaExclusao": "Ocorrência Exclusão",
  "form.dtExclusao": "Data Exclusão",
  "form.senha": "Senha",
  "form.confirmacao": "Confirmacao",
  "form.nomeusuario": "Nome de Usuário",
  "form.fator": "fator",
  "form.anoMes": "Mês / Ano",
  "servidorPensionista.siapePensionista": "Siape Pensionista",
  "servidorPensionista.siapePensionistaDv": "Dv Siape",
  "servidorPensionista.parentesco": "Parentesco",
  "servidorPensionista.upag": "Unidade Pagadora",
  "servidorPensionista.upagDescricao": "Unidade Pagadora Descrição",
  "servidorPensionista.siglaUpag": "Sigla Unidade Pagadora",
  "servidorPensionista.ufUpag": "UF da Upag",
  "servidorPensionista.tipoPensao": "Tipo Pensão",
  "servidorPensionista.ocorrenciaPensao": "Ocorrência Pensão",
  "servidorPensionista.naturezaPensao": "Natureza Pensão",
  "servidorPensionista.numeroProcessoPensao": "Número Processo Pensão",
  "servidorPensionista.dataInicioPensao": "Data Início Pensão",
  "servidorPensionista.dataFimPensao": "Data Fim Pensão",
  "servidorPensionista.codExclusaoPensionista": "Código Exclusão",
  "servidorPensionista.grupoExcPensionista": "Grupo Exclusão",
  "servidorPensionista.dataExclusaoPensionista": "Data Exclusão",
  "servidorPensionista.ocorrenciaExclusaoPensionista": "Ocorrêcia Exclusão",
  "servidorPensionista.quotaParteEspolio": "Quota Parte",
  "form.cpfPensionista": "Cpf Pensionista",
  "form.mesAniversario": "Mês de Aniversário",
  "form.sexoPensionista": "Sexo Pensionista",
  "form.dataSaidaOrgao": "Data Saida Orgão"
};
